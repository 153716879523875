import React, { useState } from "react";
// DEPENDENCIES
// COMPONENTS
import Spinner from "../Utilities/Spinner";
import { fontFamily } from "../Utilities";

// STYLES
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faInbox,
  faIdCard,
} from "@fortawesome/pro-light-svg-icons";

// Apollo, GraphQL
import { useMutation } from "@apollo/client";
import { NEWSLETTER_SIGNUP } from "../DataStructures/Mutations";

export default function NewsletterSignup(props) {
  const [emailAdded, setEmailAdded] = useState(false);
  const [newsletterSignup, { data, loading, error }] = useMutation(
    NEWSLETTER_SIGNUP,
    {
      onCompleted: (data) => {
        console.log(data);
        setEmailAdded(true);
      },
    }
  );

  const [email, setEmail] = useState("");
  function handleEmail(e) {
    setEmail(e.target.value);
  }
  function handleSubmit(e, email) {
    e.preventDefault();
    newsletterSignup({
      variables: {
        email: email,
        location: "Encounter Newsletter",
      },
    });
    return;
  }

  if (loading) return <Spinner />;
  if (error) return <h1>Server Error</h1>;
  if (emailAdded)
    return (
      <FormContainer>
        <div style={{ marginBottom: 15 }}>
          <FontAwesomeIcon icon={faPaperPlane} size="3x" />
        </div>
        <div className="form-info success">
          <h2>Success!</h2>
          <p>
            You'll periodically get new clinical encounters sent directly to
            your inbox.
          </p>
        </div>
        <div className="vl" />
        <div
          className="form-info success next"
          style={{ marginTop: 10 }}
          onClick={() =>
            (window.location.href = `https://medzcool.com/register-inline/${email}`)
          }>
          <div style={{ padding: 15 }}>
            <FontAwesomeIcon icon={faIdCard} size="3x" />
          </div>
          <h2 style={{ marginTop: 6 }}>Sign-Up</h2>
          <p>
            <a href={`https://medzcool.com/register-inline/${email}`}>
              Register
            </a>{" "}
            for a Medzcool account and explore our interactive apps.
          </p>
        </div>
      </FormContainer>
    );
  return (
    <FormContainer>
      <div style={{ marginBottom: 15 }}>
        <FontAwesomeIcon icon={faInbox} size="3x" />
      </div>
      <div className="form-info">
        <h2>Case Reports To Your Inbox</h2>
        <p>Periodically get new clinical encounters sent to your inbox.</p>
      </div>
      <div className="landing-form">
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}>
          <input
            type="email"
            value={email}
            placeholder="hello@medzcool.com"
            onChange={handleEmail}
          />

          <Button onClick={(e) => handleSubmit(e, email)}>Subscribe</Button>
        </form>
      </div>
    </FormContainer>
  );
}
const FormContainer = styled.div`
  display: grid;
  .form-info {
    margin-bottom: 10px;
    h2 {
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 0;
    }
    p {
      margin-top: 4px;
    }
  }
  .form-info.success {
    p {
      margin-bottom: 0;
    }
    &.next {
      cursor: pointer;
    }
  }
  .vl {
    border-left: 1px solid gray;
    height: 75px;
    justify-self: center;
  }
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 45px;
  form {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 15px;
  }
  input {
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    border-radius: 3px;
    border: lightgray 1px solid;
    font-size: 16px;
    font-family: ${fontFamily.sansSerif};
  }
  .landing-button {
    border-radius: 3px;
    font-weight: 500;
  }
  @media (max-width: 560px) {
    margin-top: 35px;
    form {
      grid-gap: 8px;
      grid-template-columns: 1fr;
    }
  }
`;
const Button = styled.button`
  font-family: ${fontFamily.sansSerif};
  font-weight: 500;
  border: none;
  padding: 20px;
  width: 100%;
  border-radius: 3px;
  font-weight: 500;
  cursor: pointer;
  font-size: 16px;
  color: white;
  background: black;
`;
