import styled from "styled-components";

export const fontFamily = {
  sansSerif: "futura-pt, sans-serif"
};

export const fontWeight = {
  bold: "700",
  regular: "400",
  light: "300"
};
export const H1 = styled.h1`
  font-size: 32px;
  font-family: ${fontFamily.sansSerif};
  font-weight: ${fontWeight.bold};
  margin: 0;
  margin: 20px 0;
  @media (max-width: 425px) {
    font-size: 24px;
  }
`;

export const Title = styled.h2`
  font-size: 28px;
  font-family: ${fontFamily.sansSerif};
  font-weight: ${fontWeight.bold};
  margin: 0;
  margin-bottom: 25px;
  @media (max-width: 425px) {
    font-size: 22px;
  }
`;

export const SubTitle = styled.h3`
  font-size: 20px;
  font-family: ${fontFamily.sansSerif};
  font-weight: ${fontWeight.bold};
  margin-bottom: 5px;
  @media (max-width: 425px) {
    font-size: 18px;
  }
`;

export const LargeText = styled.div`
  font-size: 18px;
  font-family: ${fontFamily.sansSerif};
  font-weight: ${fontWeight.regular};
  @media (max-width: 425px) {
    font-size: 16px;
  }
`;

export const MediumText = styled.div`
  font-size: 16px;
  font-family: ${fontFamily.sansSerif};
  font-weight: ${fontWeight.regular};
  @media (max-width: 425px) {
    font-size: 14px;
  }
`;

export const SmallText = styled.div`
  font-size: 12px;
  font-family: ${fontFamily.sansSerif};
  font-weight: ${fontWeight.regular};
  @media (max-width: 425px) {
    font-size: 10px;
  }
`;

export const AnchorTag = styled.a`
  text-decoration: none;
  font-family: ${fontFamily.sansSerif};
  ${props => (props.color ? `color: ${props.color}` : `color: #062bba`)}
`;

export const Logo = styled.div`
  font-family: ${fontFamily.sansSerif};
  color: white;
  font-weight: ${fontWeight.bold}
  font-size: 22px;
`;
