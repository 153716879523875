import React, { useEffect, Fragment } from "react";
import ReactGA from "react-ga";


// Components
import Spinner from "../Utilities/Spinner";
import CollectionCard from "../Collection/CollectionCard";
import Header from "../Header/Header";
import HeaderTransparent from "../Header/HeaderTransparent";
import Footer from "../Footer/Footer";

// Styles
import styled from "styled-components";
import { fontFamily, blue } from "../Utilities";


// Apollo, GraphQL
// import { Query } from "react-apollo";
// import gql from "graphql-tag";

// Apollo, GraphQL
import { gql, useQuery, useMutation } from '@apollo/client';

const GET_COLLECTIONS = gql`
  query GET_COLLECTIONS {
    collections {
      id
      collectionUrl
      collectionTitle
      codehealthCollectionId
      description
      thumbnail
      checkoutThumbnail
      caseCount
      price
      salePrice
      isSale
      isLive
    }
  }
`;

export default function CollectionsList(props) {
  useEffect(() => {
    window.scrollTo(0, 0);

    ReactGA.pageview("/collections");
  }, []);

  const { loading, error, data } = useQuery(GET_COLLECTIONS);

  if (loading) return null;
  if (error) return <h1>Server Error</h1>;

  return (
    <Fragment>
      {props.user ? (
        <Header user={props.user} />
      ) : (
        <HeaderTransparent />
      )}

      <FullContainer>
        <div className="text-block">
          <h1>Collections</h1>
          <p>
            Related cases are grouped into collections based a theme,
            pathology or category
          </p>
        </div>
      </FullContainer>

      <HomeContainer>
        <div className="body">
          <div className="body-grid">
            {data.collections.map(collection => {
              if (collection.isLive) {
                return (
                  <CollectionCard
                    key={collection.id}
                    collection={collection}
                    {...props}
                  />
                );
              }
            })}
          </div>
        </div>
        <div className="body more">
          <h2>More Collections Added Periodically</h2>
          <div>
            Be sure to check back frequently or sign-up for a free
            Medzcool account and we'll email you when new collections
            are available.
          </div>
          <div
            className="end-btn"
            onClick={() =>
              (window.location = "https://medzcool.com/register/rounds")
            }
          >
            Create An Account
          </div>
        </div>
      </HomeContainer>
      <Footer />
    </Fragment>
  );

  // return (
  //   <Query query={GET_COLLECTIONS}>
  //     {({ loading, data }) => {
  //       if (loading) return <Spinner />;
  //       if (data) {
  //         return (
  //           <Fragment>
  //             {props.user ? (
  //               <Header user={props.user} />
  //             ) : (
  //               <HeaderTransparent />
  //             )}

  //             <FullContainer>
  //               <div className="text-block">
  //                 <h1>Collections</h1>
  //                 <p>
  //                   Related cases are grouped into collections based a theme,
  //                   pathology or category
  //                 </p>
  //               </div>
  //             </FullContainer>

  //             <HomeContainer>
  //               <div className="body">
  //                 <div className="body-grid">
  //                   {data.collections.map(collection => {
  //                     if (collection.isLive) {
  //                       return (
  //                         <CollectionCard
  //                           key={collection.id}
  //                           collection={collection}
  //                           {...props}
  //                         />
  //                       );
  //                     }
  //                   })}
  //                 </div>
  //               </div>
  //               <div className="body more">
  //                 <h2>More Collections Added Periodically</h2>
  //                 <div>
  //                   Be sure to check back frequently or sign-up for a free
  //                   Medzcool account and we'll email you when new collections
  //                   are available.
  //                 </div>
  //                 <div
  //                   className="end-btn"
  //                   onClick={() =>
  //                     (window.location = "https://medzcool.com/register/rounds")
  //                   }
  //                 >
  //                   Create An Account
  //                 </div>
  //               </div>
  //             </HomeContainer>
  //             <Footer />
  //           </Fragment>
  //         );
  //       } else {
  //         return (
  //           <HomeContainer>
  //             <div>No Data</div>
  //           </HomeContainer>
  //         );
  //       }
  //     }}
  //   </Query>
  // );
}
const FullContainer = styled.div`
  max-width: 1300px;
  background-image: url(${props => props.backgroundImgDesktop});
  position: relative;
  background-repeat: no-repeat;
  background-size: 100%;
  height: fit-content;
  font-family: ${fontFamily.sansSerif};
  margin: 100px 0 50px 0;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  .text-block {
    h1 {
      margin-bottom: 5px;
      font-size: 55px;
    }
    .subtitle {
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 50px;
    }
    p {
      font-size: 20px;
    }
    .explore-btn {
      border: 2px solid black;
      border-radius: 10px;
      padding: 8px 25px;
      width: fit-content;
      cursor: pointer;
      margin-top: 40px;
    }
    .points {
      display: grid;
      grid-template-columns: 1fr 10fr;
      grid-gap: 20px;
      margin-bottom: 25px;
      p {
        margin-top: 0;
      }
      .points-title {
        font-weight: 700;
        font-size: 22px;
      }
    }
    .marker {
      background-color: #7f65c1;
      color: white;
      padding: 5px 15px;
      border-radius: 15px;
      align-self: center;
      justify-self: center;
      font-weight: 700;
      font-size: 18px;
    }
  }
  @media (max-width: 1200px) {
    &.collection-container {
      margin-bottom: 100px;
    }
  }
  @media (max-width: 1024px) {
    .text-block {
      h1 {
        font-size: 30px;
      }
      p {
        font-size: 18px;
      }
      .subtitle {
        font-size: 18px;
        font-weight: 400;
      }
    }
  }
  @media (max-width: 768px) {
    background-image: url(${props => props.backgroundImgMobile});
    grid-template-columns: 1fr;
    padding: 0 15px;
    &.cases-container {
      height: fit-content;
    }
    .text-block {
      h1 {
        font-size: 26px;
      }
      p {
        font-size: 18px;
      }
      .subtitle {
        font-size: 18px;
        font-weight: 400;
      }
      .explore-btn {
        margin-top: 20px;
      }
      .points {
        grid-template-columns: 1fr;
        margin-bottom: 10px;
        .points-title {
          font-size: 17px;
        }
        .marker {
          display: none;
        }
      }
    }
  }
`;
const HomeContainer = styled.div`
  padding: 0 15px;
  margin: auto;
  margin-bottom: 75px;
  max-width: 1300px;
  .heading {
    padding: 25px 0 50px 0;
    .title {
      font-weight: 700;
      font-family: ${fontFamily.sansSerif};
      font-size: 36px;
      margin-bottom: 20px;
    }
  }
  .desc {
    display: grid;
    grid-template-columns: 1.8fr 1fr;
    .heading-text {
      align-self: center;
    }
    img {
      width: 100%;
    }
    .subtitle {
      font-size: 18px;
      p {
        margin: 5px 0 0;
      }
      .beta {
        margin-top: 50px;
        display: grid;
        grid-template-columns: min-content auto;
        grid-gap: 15px;
        align-items: center;
        color: ${blue.bright};
        font-size: 13px;
      }
    }
  }
  .body {
    padding: 50px 0 50px 0;
    h2 {
      margin-bottom: 50px;
    }
    &.more {
      font-family: ${fontFamily.sansSerif};
      margin-top: 100px;
      margin-bottom: 75px;
      h2 {
        margin-bottom: 0;
      }
      .end-btn {
        background: #ff3d88;
        padding: 10px 25px;
        border-radius: 5px;
        font-weight: 700;
        color: white;
        cursor: pointer;
        width: fit-content;
        margin-top: 25px;
      }
    }
  }
  .body-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 120px 50px;
  }
  h2 {
    font-size: 28px;
    font-family: ${fontFamily.sansSerif};
  }
  @media (max-width: 1024px) {
    .body-grid {
      grid-gap: 75px 25px;
    }
  }
  @media (max-width: 768px) {
    .body {
      padding: 25px 0 50px 0;
    }
    .body-grid {
      grid-template-columns: 1fr 1fr;
    }
    h2 {
      font-size: 22px;
    }
    .heading {
      .title {
        font-size: 28px;
      }
    }
    .desc {
      grid-template-columns: 1fr;
      grid-gap: 0px;
      .subtitle {
        font-size: 16px;
      }
      img {
        display: none;
      }
    }
  }
  @media (max-width: 425px) {
    .heading {
      padding: 15px 0 25px 0;
    }
    .desc {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 15px;
    }
    .body {
      h2 {
        margin: 0px 0 35px 0;
      }
    }
    .body-grid {
      grid-template-columns: 1fr;
      grid-gap: 75px;
    }
  }
`;
