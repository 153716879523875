import React, { Fragment } from "react";

import styled from "styled-components";
// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { fontFamily, handleGAEvent, blue } from "../Utilities";

export default function ClinicCallToAction(props) {
  const { answered } = props;

  function handleClick(urlRedirect, redirectLocation) {
    handleGAEvent("Clinic", "Clicked", "Call to Action");
    window.location = urlRedirect;
  }

  return (
    <ClinicCTAContainer
      onClick={() => handleClick("https://medzcool.com/clinic", "Clinic")}
    >
      <div className="sticky-inner">
        <div className="sticky-text">
          <div>
            <h3 className="sticky-title">Looking for more cases?</h3>
            Get new medical encounters every week through our{" "}
            <strong>Clinic</strong> app. It's like a simulated medical clinic,
            where new patients present to your waiting room every week.
          </div>
          <div className="learn-more">Learn More</div>
        </div>
      </div>
    </ClinicCTAContainer>
  );
}
const ClinicCTAContainer = styled.div`
  cursor: pointer;
  bottom: 0;
  width: 100%;
  padding: 15px;
  margin-top: 0px;
  margin-bottom: 50px;
  background: ${blue.bright};
  color: white;
  font-family: ${fontFamily.sansSerif};
  .sticky-inner {
    max-width: 1100px;
    margin: auto;
  }
  .sticky-text {
    display: grid;
    margin: auto;
    grid-template-columns: 1fr auto;
    align-items: center;
    grid-gap: 50px;
    padding-bottom: 5px;
    font-size: 18px;
  }
  .sticky-title {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 22px;
  }
  .learn-more {
    border: 1px solid white;
    border-radius: 5px;
    padding: 8px 15px;
  }
  @media (max-width: 768px) {
    padding: 8px;
    .sticky-text {
      grid-template-columns: 1fr auto;
      grid-gap: 15px;
    }
  }
`;
