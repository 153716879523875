import React, { useState, Fragment } from "react";
import { useSpring, animated } from "react-spring";
import { url } from "../settings";

// Components
import Menu from "./Menu";

// Dependencies
import { Link } from "react-router-dom";

// Utilities
import { StyledButton, green, red } from "../Utilities";

// Styles
import styled from "styled-components";
import { themeColors } from "../Utilities";
import { fontFamily, fontWeight, grayscale, blue } from "../Utilities";
import { Logo } from "../Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faBars,
  faSignOut,
} from "@fortawesome/pro-regular-svg-icons";
import { faBackward } from "@fortawesome/pro-light-svg-icons";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";

import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";

const LOGOUT = gql`
  mutation logout {
    logout {
      user {
        id
      }
    }
  }
`;

export default function CaseDetailsHeader(props) {
  const user = props.user;
  const { answered, correct, collection, fromClinic } = props;

  function collectionBack() {
    props.history.push(`/collection/${props.match.params.collectionUrl}`);
    props.togglePlayUnused(false);
  }
  function homeBack() {
    window.location = "https://medzcool.com";
  }

  if (!user) {
    return (
      <HeaderContainer correct={correct} answered={answered}>
        <HeaderInnerContainer>
          <HeaderLeft>
            <Logo>
              <div className="menu-item">
                <StyledButton
                  onClick={collection ? collectionBack : homeBack}
                  fontSize={"14px;"}
                  padding={"15px;"}
                  background={"none;"}
                  width={"fit-content;"}
                  color={answered ? "black;" : "white;"}
                >
                  <FontAwesomeIcon icon={faTimes} />{" "}
                </StyledButton>
              </div>
            </Logo>
          </HeaderLeft>
        </HeaderInnerContainer>
      </HeaderContainer>
    );
  } else {
    return (
      <HeaderContainer correct={correct} answered={answered}>
        <HeaderInnerContainer>
          <HeaderLeft>
            <Logo>
              <div className="menu-item">
                <StyledButton
                  onClick={collection ? collectionBack : homeBack}
                  fontSize={"14px;"}
                  padding={"15px;"}
                  background={"none;"}
                  width={"fit-content;"}
                  color={answered ? "black;" : "white;"}
                >
                  <FontAwesomeIcon icon={faTimes} />{" "}
                </StyledButton>
              </div>
            </Logo>
          </HeaderLeft>
          <HeaderRight />
        </HeaderInnerContainer>
      </HeaderContainer>
    );
  }
}

const HeaderContainer = styled.div`
  width: 100%;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  height: 50px;
  background-color: ${themeColors.dark};
  ${(props) =>
    props.correct && props.answered && `background-color: ${green.bright}`}
  ${(props) =>
    !props.correct && props.answered && `background-color: ${red.bright}`}
`;
const HeaderInnerContainer = styled.div`
  display: grid;
  height: 100%;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  max-width: 1200px;
  margin: auto;
  padding: 0 15px;
  @media (max-width: 768px) {
    padding: 0 7px 0 0;
    grid-template-columns: auto auto;
  }
`;
const HeaderRight = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  color: white;
  justify-self: end;
  align-items: center;
  font-family: ${fontFamily.sansSerif};
  .menu-item {
    cursor: pointer;
    a {
      text-decoration: none;
    }
    &.sign-up {
      background-color: ${blue.bright};
      padding: 8px 15px;
      font-size: 14px;
      font-weight: ${fontWeight.bold};
      border-radius: 5px;
      color: white;
    }
  }
  @media (max-width: 425px) {
    font-size: 12px;
  }
`;
const HeaderLeft = styled.div`
  color: white;
  width: 300px;
  .logo-icon {
    height: 30px;
    display: none;
  }
  .logo-text {
    a {
      text-decoration: none;
      color: white;
    }
  }
  .menu-item {
    font-family: ${fontFamily.sansSerif};
    cursor: pointer;
    a {
      text-decoration: none;
      color: white;
    }
  }
  .mobile {
    display: none;
  }
  @media (max-width: 768px) {
    width: 100%;
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
  @media (max-width: 425px) {
    .logo-icon {
      display: block;
    }
    .logo-text {
      display: none;
    }
  }
`;
