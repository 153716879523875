/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_ENCOUNTER = gql`
  query encounter($hashId: String!) {
    encounter(hashId: $hashId) {
      id
      hashId
      encounterType
      title
      description
      thumbnail
      dateCreated
      attention
      isPublic
      author {
        id
        username
      }
      noteSet {
        id
        date
        text
        user {
          id
        }
      }
      tags {
        id
        name
      }
      documentSet {
        id
        title
        body
        metadata
      }
      questionSet {
        id
      }
      summary {
        id
        title
        body
        metadata
      }
      encounterlogicSet {
        id
        order
        document {
          id
          title
          body
          metadata
        }
        question {
          id
          text
          votes
          choiceSet {
            id
            text
            correctAnswer
            votes
          }
        }
        explanation {
          id
          title
          body
          metadata
        }
      }
    }
  }
`;

export const GET_COLLECTION = gql`
  query getCollection($collectionId: Int, $collectionUrl: String) {
    getCollection(collectionId: $collectionId, collectionUrl: $collectionUrl) {
      id
      collectionUrl
      collectionTitle
      thumbnail
      description
      codehealthCollectionId
      priceId
      price
      salePrice
      caseCount
      isSale
      encounterSet {
        id
        hashId
        encounterType
        title
        description
        thumbnail
        dateCreated
        attention
        isPublic
        clinicEncounter
        incomplete
        author {
          id
          username
        }
        tags {
          id
          name
        }
      }
    }
  }
`;

export const GET_COLLECTION_STATE = gql`
  query getCollectionState($collectionId: Int!, $userId: Int!) {
    getCollectionState(collectionId: $collectionId, userId: $userId) {
      id
      state
    }
  }
`;
