import React, { useState, Fragment } from "react";
import { useSpring, animated } from "react-spring";

// Components
import Menu from "./Menu";

// Dependencies
import { Link } from "react-router-dom";

// Styles
import styled from "styled-components";
import { themeColors, fontFamily, fontWeight, blue } from "../Utilities";
import { Logo } from "../Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-regular-svg-icons";
import { faChevronLeft, faLayerGroup } from "@fortawesome/pro-solid-svg-icons";
import { faFirstAid } from "@fortawesome/pro-light-svg-icons";

// import { Mutation } from "react-apollo";
// import gql from "graphql-tag";

// Apollo, GraphQL;
import { gql, useMutation } from "@apollo/client";

const LOGOUT = gql`
  mutation logout {
    logout {
      user {
        id
      }
    }
  }
`;

export default function HeaderTransparent(props) {
  const user = props.user;
  const { onCollectionPage } = props;

  const [logout] = useMutation(LOGOUT);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }
  const menuAnimation = useSpring({
    transform: isMenuOpen ? `translate3d(0, 0, 0)` : `translate3d(100%, 0, 0)`,
  });
  const menuOpacity = useSpring({
    opacity: isMenuOpen ? 0.5 : 0,
    display: isMenuOpen ? `block` : `none`,
  });

  function renderBackNav() {
    if (onCollectionPage) {
      return (
        <div className="back-icon">
          <FontAwesomeIcon icon={faChevronLeft} className="icon" />
          <FontAwesomeIcon icon={faLayerGroup} className="icon" />
        </div>
      );
    } else {
      return (
        <div className="back-icon">
          <span className="logo-medzcool">CLINICAL ROUNDS</span>
        </div>
      );
    }
  }

  if (!user) {
    return (
      <HeaderContainer>
        <HeaderInnerContainer>
          <HeaderLeft>
            <Logo>
              <Link to="/">
                <FontAwesomeIcon
                  icon={faFirstAid}
                  size="2x"
                  className="logo-icon"
                />
              </Link>

              <span className="logo-text">
                <Link to="/">{renderBackNav()}</Link>
              </span>
            </Logo>
          </HeaderLeft>
          <HeaderRight className="non-auth">
            <div className="desktop">
              <span className="menu-item auth-btn">
                <a href="https://medzcool.com/login/rounds">SIGN IN</a>
              </span>

              <span
                className="menu-item auth-btn get-started"
                onClick={() =>
                  (window.location = "https://medzcool.com/register/rounds")
                }>
                GET STARTED
              </span>
            </div>
          </HeaderRight>
        </HeaderInnerContainer>
      </HeaderContainer>
    );
  } else {
    return (
      <HeaderContainer>
        <HeaderInnerContainer>
          <HeaderLeft>
            <Logo>
              <div className="menu-item">
                <Link to="/">{renderBackNav()}</Link>
              </div>
            </Logo>
          </HeaderLeft>
          <HeaderRight>
            <Fragment>
              <Menu
                style={menuAnimation}
                toggleMenu={toggleMenu}
                opacity={menuOpacity}
                logout={logout}
              />
            </Fragment>
            <a href="https://medzcool.com" className="menu-item desktop">
              HOME
            </a>
            <a href="/" className="menu-item desktop">
              ROUNDS
            </a>
            <div
              className="menu-item"
              onClick={() => setIsMenuOpen(!isMenuOpen)}>
              <FontAwesomeIcon icon={faBars} size="lg" />
            </div>
          </HeaderRight>
        </HeaderInnerContainer>
      </HeaderContainer>
    );
  }
}

const HeaderContainer = styled.div`
  width: 100%;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  height: 50px;
  border-bottom: solid 1px lightgray;
  a {
    text-decoration: none;
  }
`;
const HeaderInnerContainer = styled.div`
  display: grid;
  height: 100%;
  align-items: center;
  grid-template-columns: auto 1fr;
  margin: auto;
  padding: 5px;
  background: white;
`;
const HeaderRight = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  justify-self: end;
  align-items: center;
  font-family: ${fontFamily.sansSerif};
  &.non-auth {
    grid-template-columns: auto auto;
  }
  .menu-item {
    cursor: pointer;
    margin-left: 55px;
    a {
      text-decoration: none;
      color: black;
    }
    &.auth-btn {
      margin-left: 30px;
    }
    &.get-started {
      background: ${blue.bright};
      padding: 10px 25px;
      border-radius: 5px;
      font-weight: 700;
      color: white;
      cursor: pointer;
    }
  }
  @media (max-width: 768px) {
    .menu-item {
      &.desktop {
        display: none;
      }
    }
  }
  @media (max-width: 425px) {
    .menu-item {
      &.auth-btn {
        margin-left: 20px;
      }
      &.get-started {
        padding: 8px;
      }
    }
  }
`;
const HeaderLeft = styled.div`
  .back-icon {
    width: fit-content;
    .icon {
      margin-right: 8px;
    }
    .logo-medzcool {
      position: relative;
      top: -2px;
      margin-left: 8px;
      color: black;
    }
  }
  .logo-icon {
    color: black;
    height: 30px;
    display: none;
  }
  .logo-text {
    a {
      text-decoration: none;
    }
  }
  .menu-item {
    font-family: ${fontFamily.sansSerif};
    cursor: pointer;
    a {
      text-decoration: none;
    }
  }
  .mobile {
    display: none;
  }
  @media (max-width: 768px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
    .logo-icon {
      display: block;
    }
    .logo-text {
      display: none;
    }
  }
  @media (max-width: 425px) {
    .back-icon {
      .logo-medzcool {
        display: none;
      }
    }
  }
`;
