import React from "react";

// Styles
import styled from "styled-components";

// Utilities
import { StyledButton } from "../Utilities";

export default function CollectionFooter(props) {
  if (!props.isSubscribed) return null;
  return (
    <FooterContainer>
      <FooterSubContainer>
        <StyledButton
          active
          width={"fit-content"}
          onClick={props.resetCollectionState}
        >
          RESET COLLECTION
        </StyledButton>

      </FooterSubContainer>
    </FooterContainer>
  );
}

const FooterContainer = styled.div`
  z-index: 20;
  padding: 18px 0 32px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f7f7f7;
  border-top: 1px solid #e7e7e7;
`;
const FooterSubContainer = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 0 15px 15px 15px;
`;
