import React, { Fragment } from "react";

// Elements
import { StyledLabel } from "../Utilities";

// Components
import DraftReadOnly from "./DraftReadOnly";

function CaseQuestionAnswer(props) {
  const { caseObject, createMarkup } = props;

  let questionLayout;

  if (caseObject.caseType == "question") {
      questionLayout = <StyledLabel>Answer Choices</StyledLabel>;
    } else {
      questionLayout = (
        <Fragment>
          <DraftReadOnly
            label="Answer Choices"
            propsEditorState={caseObject.questions[0].question}
          />
        </Fragment>
      );
    }

  return questionLayout;
}

export default CaseQuestionAnswer;
