import styled from "styled-components";

export const fontFamily = {
  sansSerif: "futura-pt, sans-serif",
};

export const fontWeight = {
  bold: "700",
  regular: "400",
  light: "300"
};
