import React from "react";
import { url } from "../settings";

// Components

// Dependencies
import { Link } from "react-router-dom";

// Utilities
import { CaseBlock, StyledButton, blue } from "../Utilities";

// Styles
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faForward, faBackward } from "@fortawesome/pro-solid-svg-icons";

export default function EncounterFooter(props) {
  const { collection, answered } = props;

  function collectionBack() {
    props.history.push(`/collection/${props.match.params.collectionUrl}`);
    props.togglePlayUnused(false);
  }

  if (!answered) return null;

  return (
    <FooterContainer>
      <FooterInner>
        {collection && props.answered && (
          <StyledButton
            className="footer-button"
            onClick={collectionBack}
            fontSize={"13px;"}
            padding={"12px;"}
            background={"#393939;"}
          >
            <FontAwesomeIcon icon={faBackward} />{" "}
            <span className="text-right">Back to Collection</span>
          </StyledButton>
        )}

        {props.collectionState.unansweredCases.length > 0 &&
          props.playingUnused &&
          props.answered && (
            <StyledButton
              onClick={props.nextCase}
              className="footer-button"
              active={props.answered}
              background={`${blue.neutral};`}
              color={"white;"}
              fontSize={"13px;"}
              padding={"12px;"}
            >
              <span className="text-left">Next Case</span>
              <FontAwesomeIcon icon={faForward} />
            </StyledButton>
          )}
      </FooterInner>
    </FooterContainer>
  );
}

const FooterBlock = styled.div`
  cursor: pointer;
  display: inline-block;
  text-align: center;
  padding: 0 25px;
  span {
    font-family: futura-pt, sans-serif;
    margin-left: 8px;
  }
  a {
    text-decoration: none;
    color: black !important;
  }
  @media (max-width: 992px) {
    padding: 0;
    min-width: 20%;
    flex: 1 1 auto;
    position: relative;
    span {
      display: block;
      padding-top: 5px;
      margin-left: 0 !important;
      font-size: 8px;
      position: absolute;
      width: 100%;
      text-align: center;
    }
  }
`;
const FooterContainer = styled.div`
  z-index: 20;
  padding: 18px 10px 32px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f7f7f7;
  border-top: 1px solid #e7e7e7;
  @media (max-width: 768px) {
    height: 88px;
    padding: 10px 10px 32px;
  }
`;
const FooterInner = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  max-width: 1100px;
  margin: auto;
  grid-gap: 15px;
  .footer-button {
    /* grid-column-end: 5; */
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  /* @media (max-width: 768px) {
    .footer-button {
        display: none;
    }
  } */
  @media (max-width: 625px) {
    grid-template-columns: 1fr 1.3fr;
    grid-gap: 10px;
  }
`;
const PaddedSpan = styled.span`
  margin: 0 8px;
`;
const Loading = styled.div`
  font-family: adobe-text-pro, serif;
  font-size: 14px;
  text-align: center;
  color: gray;
`;
