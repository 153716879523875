/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const ANSWER_ENCOUNTER = gql`
  mutation AnswerEncounter(
    $selectedChoices: JSONString
    $userId: Int
    $encounterId: Int!
    $eventLocation: String
  ) {
    answerEncounter(
      selectedChoices: $selectedChoices
      userId: $userId
      encounterId: $encounterId
      eventLocation: $eventLocation
    ) {
      answer
      encounter {
        id
        hashId
        title
        description
        thumbnail
        documentSet {
          id
          title
          body
          metadata
        }
        encounterlogicSet {
          id
          question {
            id
            text
            votes
            choiceSet {
              id
              text
              correctAnswer
              votes
            }
          }
          explanation {
            id
          }
        }
      }
    }
  }
`;

export const NEWSLETTER_SIGNUP = gql`
  mutation NewsletterSignup($email: String!, $location: String!) {
    newsletterSignup(email: $email, location: $location) {
      email
    }
  }
`;
