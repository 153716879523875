import React, { useState, useEffect, Fragment } from "react";
import { url, homeUrl } from "../settings";
import ReactGA from "react-ga";

// Dependencies
import { Link, Route } from "react-router-dom";
import axios from "axios";

// Components
import Spinner from "../Utilities/Spinner";
import { StyledButton, yellow, red, blue } from "../Utilities";

// Styles
import styled from "styled-components";
import { H1, LargeText, fontFamily } from "../Typography";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";

export default function CollectionCard(props) {
  const { collection, user } = props;

  function handleGAEvent(category, action, label) {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  }

  function handleGetCollection() {
    props.history.push(`collection/${collection.collectionUrl}`);
    handleGAEvent("Collection Card", "Clicked", "Get Collection");
  }

  function handleLearnMoreCollection() {
    props.history.push(`collection/${collection.collectionUrl}`);
    handleGAEvent("Collection Card", "Clicked", "Learn More");
  }

  function toCurrency(number) {
    const integer = parseInt(number);
    const currency = (integer / 100).toFixed(2);
    return currency;
  }
  const [isSubscribed, setIsSubscribed] = useState(false);
  useEffect(() => {
    if (user) {
      const subscribedRounds = user.profile.subscribedRounds;
      subscribedRounds.map((subcribedCollection) => {
        if (subcribedCollection.id == collection.id) {
          setIsSubscribed(true);
        }
      });

      if (user.profile.subscriptionStatus == "active") {
        setIsSubscribed(true);
      }
    }
  }, []);

  return (
    <CollectionCardContainer>
      <div className="img-container">
        {props.collection.isSale && !isSubscribed && (
          <div className="sale-banner">
            ON SALE:{" "}
            <span className="price">
              {"$" + toCurrency(collection.salePrice)}
            </span>
          </div>
        )}

        <img
          src={collection.thumbnail}
          onClick={() =>
            props.history.push(`collection/${collection.collectionUrl}`)
          }
        />
      </div>
      <div className="collection-desc">
        <LargeText className="desc-block">
          <strong className="title">{collection.collectionTitle}</strong>
          <LargeText className="case-count">
            {collection.caseCount + " cases"}
          </LargeText>
        </LargeText>

        {!isSubscribed && (
          <LargeText className="desc-block">
            <strong className="inline">
              {props.collection.isSale ? (
                <Fragment>
                  <span className="price strikethrough">
                    {"$" + toCurrency(collection.price)}
                  </span>
                  <span className="price">
                    {"$" + toCurrency(collection.salePrice)}
                  </span>
                </Fragment>
              ) : (
                "$" + toCurrency(collection.price)
              )}
            </strong>
          </LargeText>
        )}

        <LargeText>{collection.description}</LargeText>
      </div>

      {isSubscribed ? (
        <div className="button">
          <StyledButton
            active
            background={`${blue.bright}`}
            color={"white"}
            onClick={() =>
              props.history.push(`collection/${collection.collectionUrl}`)
            }
            boxShadow={"0px 5px 5px rgba(0,0,0,0.1);"}
            borderRadius={"4px;"}
            fontWeight={"700"}
          >
            <FontAwesomeIcon icon={faPlay} />
            <span className="view-collection-text">VIEW COLLECTION</span>
          </StyledButton>
        </div>
      ) : (
        <div className="buttons">
          <div
            onClick={() => handleLearnMoreCollection()}
            className="learn-more"
          >
            Learn More
          </div>
          <StyledButton
            active
            onClick={() => handleGetCollection()}
            borderRadius={"4px;"}
            boxShadow={"0px 5px 5px rgba(0,0,0,0.1);"}
            background={collection.isSale ? red.pink : blue.bright}
            color={"white"}
          >
            GET COLLECTION
          </StyledButton>
        </div>
      )}
    </CollectionCardContainer>
  );
}

const CollectionCardContainer = styled.div`
  .img-container {
    position: relative;
    .sale-banner {
      position: absolute;
      background: #fd2c9b;
      padding: 8px 15px;
      top: 15px;
      left: -10px;
      color: white;
      font-family: futura-pt, sans-serif;
      font-weight: 700;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    }
  }
  img {
    width: 100%;
    max-height: 250px;
    object-fit: cover;
    cursor: pointer;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  }
  .collection-desc {
    padding: 0 0 15px 0;
    .price {
      margin-right: 8px;
      &.strikethrough {
        text-decoration: line-through;
        font-weight: 300;
      }
    }
    .case-count {
      font-size: 14px;
      color: gray;
    }
  }
  .desc-block {
    margin: 15px 0;
    .title {
      font-size: 22px;
    }
    .inline {
      display: inline-block;
    }
    .spacer {
      margin: 0 6px;
    }
  }
  .buttons {
    margin: 8px 0;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 10px;
    .learn-more {
      color: #295aec;
      text-decoration: none;
      align-self: center;
      font-family: futura-pt, sans-serif;
      cursor: pointer;
    }
  }
  .button {
    margin: 8px 0;
    .view-collection-text {
      margin-left: 10px;
    }
  }
  @media (max-width: 768px) {
    .collection-desc {
      padding: 0 0 15px 0;
    }
  }
`;
