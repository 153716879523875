import React, { useEffect } from "react";

// Styles
import styled from "styled-components";

export default function HorizontalResponsiveAd(props) {
  const { border } = props;

  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <AdContainer>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-3581415225953957"
        data-ad-slot="3843260445"
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
    </AdContainer>
  );
}

const AdContainer = styled.div`
  padding: 20px 5px;
  margin: 20px 0;
`;
