export const featuredCasesData = {
  data: {
    featuredCases: [
      {
        id: "GRb4kgaB",
        title: "Shortness of breath after an MI",
        desc:
          "An elderly man complains of trouble breathing. He was recently hospitalized for an inferior wall myocardial infarction. On exam, he has diffuse rales on auscultation of his lungs and a new holosystolic murmur that is loudes at the apex and radiates to the axilla.",
      },
      {
        id: "l9avYgeG",
        title: "Ptosis & Progressive Weakness",
        desc:
          "A young female presents for worsening weakness and shortness of breath over the past 1-2 months. On exam, you notice bilateral ptosis, but an otherwise normal physical exam including normal vital signs.",
      },
      {
        id: "8mepLyeM",
        title: "Fever, Pain & Rash",
        description: "4-year-old boy with fever and severe pain in his fingers and toes, and the development of a rash."
      },
      {
        id: "8mepg2dM",
        title: "Unresponsive",
        desc:
          "A middle-aged patient is found unconscious on her apartment floor. Paramedics found the patient to be pulseless and in cardiac arrest.",
      },
      {
        id: "MvbmqpbY",
        title: "Bradycardia and Syncope",
        desc:
          "A 55-year-old female with end-stage renal disease presents to the emergency department for weakness and loss of consciousness. She is brought in by ambulance found to be confused and hypotensive.",
      },
      {
        id: "xkazYeJ0",
        title: "Cardiac arrest",
        desc:
          "A young patient with Brugada Syndrome, presents in cardiac arrest.",
      },
      {
        id: "YQdJ7veO",
        title: "Worsening Headache",
        desc:
          "A 77-year-old female who was recently diagnosed with a brain tumor prents for worsening headaches. On exam, you notice that there is a ptosis of her right eyelid",
      },
      {
        id: "l9avDMdG",
        title: "Palpitations & Fatigue",
        desc:
          "A 60-year-old man with a medical history of COPD (with a chronic smoking history) and diabetes mellitus comes to the hospital complaining of palpitations.",
      },
      {
        id: "4zbqADbp",
        title: "A History of Palpitations",
        desc:
          "A young female patient presents to the hospital for complaints of palpitations. She tells you that she had had similar symptoms before and was seen at a hospital where they gave her medication through an IV.",
      },
      {
        id: "46dBnnd7",
        title: "Hyponatremia",
        desc:
          "Which of the following patients would rapid serum sodium correction with 3% hypertonic saline be contraindicated?",
      },
      {
        id: "w9aAApav",
        title: "Chest Pain and Dyspnea",
        desc:
          "A 67-year-old woman presents with chest pain and dyspnea. Soon after arrival, she experiences a witnessed cardiac arrest.",
      },
      {
        id: "ELe3j4d6",
        title: "Headache, Confusion and Hypertension",
        desc:
          "A 50 year old man presents for headache. Family members tell you that over the past few days, their father has been confused.",
      },
      {
        id: "Jrb22WbW",
        title: "Syncope",
        desc:
          "A 25-year-old man with a recent syncopal episode is admitted to the hospital because of congestive heart failure.",
      },
      {
        id: "y5eVlObE",
        title: "Visual disturbance after fall",
        desc:
          "A 65-year-old man with is brought to the emergency department after fall down the stairs. He reports some disturbances in his vision after waking up this morning that was not present previously.",
      },
      {
        id: "nXe0E3ax",
        title: "Nausea, Vomting & Abdominal Pain",
        desc:
          "A 30-year-old G2P1 pregnant female, at 32 weeks of an uncomplicated pregnancy, complains of abdominal pain.",
      },
      {
        id: "K9b6GzdE",
        title: "Pediatric Fever",
        desc:
          "A 6-year-old boy is brought to the office because of a fever for 3 days, a systolic murmur and viral respiratory symptoms.",
      },
      {
        id: "8mepRXbM",
        title: "Frostbite",
        desc:
          "A man is brought to the hospital after found lying on a bench in a park after a blizzard. On exam both of his feet are cool and cyanotic. You suspect frostbite to the lower extremities.",
      },
      {
        id: "BDbDwAal",
        title: "Bicyclist struck by a car",
        desc:
          "A bicyclist is struck by a car. He is confused afterwards and his eyes are closed, but they open to verbal stimulation.",
      },
      {
        id: "olej5Bdj",
        title: "Weakness and Heart Failure",
        desc:
          "A 62-year-old man with heart failure complains of profound weakness after a mycoardial infarction.",
      },
      {
        id: "9wdLwbjP",
        title: "APGAR Newborn Assessment",
        desc:
          "You have just delivered a newborn and now you are assessing the APGAR score for this baby. She is pink all over with a pulse of 130. She cries vigorously and is moving all 4 extremities.",
      },
      {
        id: "pmbkBKaz",
        title: "Coarctation of the Aorta",
        desc:
          "What are features/syndromes not associated with coarctation of the aorta?",
      },
      {
        id: "46dB82a7",
        title: "Pesticide Exposure",
        desc:
          "A male farmer presents after being exposed to pesticide. He has signs and symptoms of organophosphate poisoning and you treat him with atropine.",
      },
      {
        id: "LDdwEga1",
        title: "Knee Pain, Fever & Rash",
        desc:
          "A 3-year-old girl presents for right knee pain and fever. She has a diffuse urticarial rash, and her right knee is swollen along with several other joints.",
      },
      {
        id: "GRb490dB",
        title: "Trauma and Assault",
        desc:
          "Unconcious after blunt trauma. The patient's eyes are closed as they are brought into the trauma resuscitation bay. What is this patient's Glasgow Coma Scale?",
      },
      {
        id: "MYerEBdO",
        title: "Palpitations",
        desc:
          "A female patient with a medical history of diabetes and hypertension has palpitations which started approximately 2 hours ago while she was cooking dinner.",
      },
      {
        id: "Jxbo8kdg",
        title: "The Room is Spinning",
        desc:
          "A 55 year old man with with no past medical history presents with a complaint of dizziness which he describes as 'the room is spinning'.",
      },
      {
        id: "8mepzrbM",
        title: "Congential Heart Defects",
        desc:
          "What congenital heart pathology can be categorized as an acyanotic congenital cardiac defect?",
      },
      {
        id: "M7e5KXb2",
        title: "Fever and Confusion",
        desc:
          "A young female presents for high-grade fever and confusion. Family members report that she's had similar symptoms in the past.",
      },
      {
        id: "K9b6KNdE",
        title: "Motor Vehicle Accident",
        desc:
          "A man arrives unconscious after an accident. He is tachycardic and hypotensive. You are concenred that he has a pelvic fracture. What parenteral fluids are indicated?",
      },
      {
        id: "5xe7VBd7",
        title: "Chest Pain & Facial Droop",
        desc:
          "A man has sudden onset sharp chest pain, subsequently followed by a facial droop. What assessment can further determine the cause of these signs and symptoms?",
      },
      {
        id: "ELe3Mb69",
        title: "A Heart murmur",
        desc:
          "You hear a diastolic heart murmur accompanied by an opening snap. This murmur is louder with a Valsalva maneuver and best heard at the cardiac apex.",
      },
      {
        id: "w9aAWjdv",
        title: "Fatigue during exercise",
        desc:
          "A boy presents for profound fatigue during exercise. He is unable to run for more than a few minutes without becoming extremely short of breath. What is the cause for his symptoms?",
      },
      {
        id: "l9avjgaG",
        title: "Succinylcholine in Rapid Sequence Induction/Intubation",
        desc:
          "In which of the following scenarios is succinylcholine a safe alternative to rocuronium during intubation?",
      },
      {
        id: "Vyb8Krav",
        title: "Shortness of breath after house fire",
        desc:
          "A 58-year-old male presents with shortness of breath and hoarseness following a house fire.",
      },
      {
        id: "w9aAKjdv",
        title: "Hypotension after a traumatic injury",
        desc:
          "A 35-year-old G5P4 female at 35 weeks gestation, is brought into the hospital after a motor vehicle accident.",
      },
      {
        id: "zPdy6zeQ",
        title: "A man with palpitations",
        desc:
          "A 60-year-old man with hypertension complains of palpitations.",
      },
      {
        id: "M7e57xb2",
        title: "Confusion after overdose",
        desc:
          "A female is found in her apartment floor, confused, after ingesting an entire bottle of Aspirin.",
      },
      {
        id: "8mepoQdM",
        title: "Easy Bruising and Fatigue",
        desc:
          "60-year-old man has been experiencing fatigue and easy bruising over the past few weeks.",
      },
      {
        id: "3YaOZGex",
        title: "Rash On Hands & Feet",
        desc:
          "A 32-year-old man presents with a rash on the palms of his hands and soles of his feet, accompanied by bodyaches and fatigue.",
      },
      {
        id: "MvbmkReY",
        title: "Vision Loss",
        desc:
          "A 52-year-old male presents with sudden onset, painless vision loss in his right eye. What is the likely diagnosis?",
      },
      {
        id: "WPe9AJeL",
        title: "Fever and Joint Pain",
        desc:
          "A 26-year-old man has fevers and joint pain. He has a rash on his hands, a few scattered palpable purpura and a right knee effusion.",
      },
      {
        id: "BDbDx6bl",
        title: "Severe Headache",
        desc:
          "A 36-year-old female with a history of a history of polycystic kidney disease, presents with a severe headache.",
      },
      {
        id: "pmbkDKdz",
        title: "Fever and a new murmur",
        desc:
          "A 25 year-old-man presents with fever. He is an active IV drug user. On exam, there is a holosystolic murmur best heard on the left lower sternal border.",
      },
      {
        id: "4QbYrWdz",
        title: "Fever after dental procedure",
        desc:
          "10-year-old girl presents for fever, fatigue after a a tooth extraction 2 days ago.",
      },
      {
        id: "pnelv7bK",
        title: "Fever and rash",
        desc:
          "A 3-year boy has a fever for the past 3 days and a rash. His immunizations are not up to date. He has suboccipital lymphadenopathy. What is the likely diagnosis?",
      },
      {
        id: "YRdGAKaD",
        title: "Rash on face",
        desc:
          "A 55-year-old man presents for a rash on his face and limbs. He reports a prodrome of fever and bodyaches. How would you best characterize the lesions caused by this disease?",
      },
      {
        id: "J0dNG2eL",
        title: "Facial Droop",
        desc:
          "A 43-year-old woman presents for a right-sided facial droop. Her physical examination is notable for an inability to raise her eyebrow on the affected side. What is the most likely diagnosis?",
      },
      {
        id: "N1aMXmeW",
        title: "Right-Sided Facial Droop",
        desc:
          "A 66-year-old man presents to your clinic because he is concerned that he is having a stroke. His physical exam is notable for a right-sided facial droop.",
      },
      {
        id: "4oeEyWe0",
        title: "Barky Cough and Stridor",
        desc:
          "A 2-year-old boy presents for a cough. On exam, you appreciate intermittent short barky coughing with faint inspiratory stridor.",
      },
      {
        id: "46dBvQd7",
        title: "Slurred Speech",
        desc:
          "A 53-year-old woman presents for a headache and slurred speech. On exam, she is obtunded. BP 260/140.",
      },
      {
        id: "WZdPGAbK",
        title: "First Seizure",
        desc:
          "AA 48-year-old	female presents after a first time seizure. Over the past week, she reports weakness in lifting her left arm. Exam reveals the skin lesions seen here.",
      }
    ],
  },
};
