import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { url } from "./settings";
import * as serviceWorker from "./serviceWorker";

// GraphQL
// import gql from "graphql-tag";

// Apollo
// import ApolloClient from "apollo-boost";
// import { ApolloProvider, Query } from "react-apollo";
// import { InMemoryCache } from "apollo-cache-inmemory";

// const cache = new InMemoryCache();
// const client = new ApolloClient({
//   cache,
//   uri: `${url}/graphql/`,
//   resolvers: {},
//   credentials: "include",
// });

// ReactDOM.render(
//   <ApolloProvider client={client}>
//     <App client={client} />
//   </ApolloProvider>,
//   document.getElementById("root")
// );

// Apollo
import { ApolloProvider, InMemoryCache, ApolloClient } from "@apollo/client";

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: `${url}/graphql/`,
  resolvers: {},
  credentials: "include",
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App client={client} />
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
