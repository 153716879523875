export default function createEvent(
    userId = null,
    location = null,
    category = null,
    action = null,
    label = null,
    description = null,
  ) {
    return new Promise((resolve, reject) => {
      fetch(`https://api.medzcool.com/create-event/`, {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          userId: userId,
          location: location,
          category: category,
          action: action,
          label: label,
          description: description,
        }),
      })
        .then((response) => {
          // console.log(response);
          return response.json();
        })
        .then((result) => {
          if (result.error) {
            reject(result.error);
          }
          resolve(result);
        });
    });
  }
  