import React, { useState, useEffect } from "react";
import { mixpanelToken } from "../../src/settings";

// DEPENDENCIES
import { Route } from "react-router-dom";
import LiveEncounter from "@bit/medzcool.cytoplasm.live-encounter";
import { BaseType } from "@bit/medzcool.cytoplasm.typography";
import mixpanel from "mixpanel-browser";
import VisibilitySensor from "react-visibility-sensor";

// COMPONENTS
import CaseDetails from "../CaseDetails/CaseDetails";
import PublicCTA from "./PublicCTA";
import EncounterFooter from "../Collection/EncounterFooter";
import clinicPromo from "../Ads/clinic-case-promo.jpg";
import { shuffle, fontFamily, fontWeight, blue } from "../Utilities";
import ClinicalCaseCard from "../CaseDetails/ClinicalCaseCard";
import createEvent from "../Events/createEvent";
import NewsletterSignup from "./NewsletterSignup";

// STYLES
import styled from "styled-components";

// Apollo, GraphQL
import { useQuery, useMutation } from "@apollo/client";
import { GET_ENCOUNTER } from "../DataStructures/Queries";
import { ANSWER_ENCOUNTER } from "../DataStructures/Mutations";
import { featuredCasesData } from "../FeaturedCasesData";

mixpanel.init(mixpanelToken);

export default function EncounterRouter(props) {
  const { handleGAEvent, user, collectionCaseDetails } = props;

  function url(url) {
    if (url) {
      return url.match(/:\/\/(.[^/]+)/)[1];
    }
  }

  const [youtubeReferral, setYoutubeReferrer] = useState();
  useEffect(() => {
    // console.log("referrer", document.referrer);
    if (document.referrer.length > 0) {
      if (url(document.referrer).includes("youtube.com")) {
        setYoutubeReferrer(true);
      }
    }
    // if (
    //   document.referrer.length <= 0 ||
    //   url(document.referrer) !== "www.youtube.com"
    // ) {
    //   return <h1>You are NOT a member</h1>;
    // }
  }, []);

  const hashId = props.match.params.caseId;

  const [encounterAnswered, setEncounterAnswered] = useState(false);
  const [answerEncounter] = useMutation(ANSWER_ENCOUNTER, {
    onCompleted(data) {
      setEncounterAnswered(true);

      if (user) {
        mixpanel.identify(user.id);

        mixpanel.people.set_once({
          $email: user.email,
        });
      }

      mixpanel.track("Encounter", {
        App: "Rounds",
        Action: "Answered",
        Answer: data.answerEncounter.answer,
        "Encounter ID": data.answerEncounter.encounter.id,
        "Encounter Hash ID": data.answerEncounter.encounter.hashId,
      });

      if (collectionCaseDetails) {
        props.handleCaseAnswerState(
          data.answerEncounter.answer,
          data.answerEncounter.encounter.hashId
        );
      }
    },
  });

  const [featuredCases, setFeaturedCases] = useState();
  useEffect(() => {
    setFeaturedCases(shuffle(featuredCasesData.data.featuredCases));
  }, []);

  const [visibleCTAFloat, setVisibleCTAFloat] = useState(true);
  function renderPublicCTA() {
    if (!visibleCTAFloat) return null;

    if (encounterAnswered) {
      if (!user || user.profile.subscriptionStatus !== "active") {
        return <PublicCTA encounter={data.encounter} />;
      }
    }
  }

  // Query to find if encounter exists under records table
  const { loading, error, data } = useQuery(GET_ENCOUNTER, {
    variables: { hashId },
  });

  if (loading) return null;
  if (!data || !data.encounter) {
    if (props.match.url.includes("collection")) {
      return collectionCaseDetails;
    }
    return (
      <Route
        exact
        path="/case/:caseId"
        render={(props) => (
          <CaseDetails {...props} user={user} handleGAEvent={handleGAEvent} />
        )}
      />
    );
  }

  // if it exists, redirect user to new encounter view
  if (data.encounter) {
    if (youtubeReferral) {
      console.log("This was a youtube.com referral");
    }

    if (props.match.url.includes("collection")) {
      return (
        <BaseType>
          <LiveEncounter
            encounter={data.encounter}
            answerEncounter={answerEncounter}
            user={props.user}
            inCollection={true}
            exitLocation={() =>
              props.history.push(
                `/collection/${props.match.params.collectionUrl}`
              )
            }
            eventLocation="rounds"
          />
          {encounterAnswered && (
            <EncounterFooter
              {...props}
              collection={props.collection}
              collectionState={props.collectionState}
              answered={encounterAnswered}
            />
          )}
        </BaseType>
      );
    }

    if (!data.encounter.isPublic) {
      window.location = "https://www.medzcool.com";
      return null;
    }

    function handleCallToAction(location) {
      let userId;
      if (user) {
        userId = user.id;
      } else {
        userId = null;
      }

      createEvent(
        userId,
        "rounds",
        location,
        "clicked",
        "clinic",
        "When user clicks on the top advertisement after they have answered a public encounter"
      ).then((result) => {
        mixpanel.track("Call To Action", {
          App: "Rounds",
          Location: "Top Inline Advertisement",
          "Encounter ID": data.encounter.id,
          "Encounter Hash ID": data.encounter.hashId,
          "CTA For": "Clinic",
          "Redirect URL": "medzcool.com/clinic",
        });
        window.location = "https://medzcool.com/clinic";
      });
    }

    function renderAdvertisementOne() {
      if (encounterAnswered) {
        if (!user || user.profile.subscriptionStatus !== "active") {
          return (
            <img
              src={clinicPromo}
              onClick={() => handleCallToAction("advertisementOne")}
              style={{ width: `100%`, cursor: `pointer`, marginBottom: 10 }}
            />
          );
        }
      }
      return;
    }

    function renderBottom() {
      if (encounterAnswered) {
        return (
          <RelatedCasesContainer>
            <h2>More Clinical Encounters</h2>
            {featuredCases.slice(0, 2).map((caseObj) => {
              if (caseObj.id !== hashId) {
                return (
                  <div
                    className="clinical-case-card-container"
                    key={caseObj.id}>
                    <ClinicalCaseCard
                      mixpanel={mixpanel}
                      caseId={caseObj.id}
                      title={caseObj.title}
                      desc={caseObj.desc}
                      currentEncounter={data.encounter}
                    />
                  </div>
                );
              } else {
                return null;
              }
            })}

            <VisibilitySensor
              partialVisibility
              onChange={(isVisible) => {
                setVisibleCTAFloat(!isVisible);
              }}>
              <EmailCTA>
                <NewsletterSignup />
              </EmailCTA>
            </VisibilitySensor>
          </RelatedCasesContainer>
        );
      }
    }

    return (
      <BaseType>
        {renderPublicCTA()}
        <LiveEncounter
          encounter={data.encounter}
          answerEncounter={answerEncounter}
          user={props.user}
          exitLocation={() =>
            (window.location = "https://www.medzcool.com/encounter-exit")
          }
          eventLocation="rounds"
          advertisementOne={renderAdvertisementOne()}
          bottom={renderBottom(data.encounter)}
        />
      </BaseType>
    );
  }
}
const EmailCTA = styled.div`
  margin-top: 15px;
  border-radius: 5px;
  box-shadow: 2px 4px 9px rgb(0 0 0 / 8%);
  background: #f5f8ff;
`;
const RelatedCasesContainer = styled.div`
  font-family: ${fontFamily.sansSerif};
  margin: 50px 0px;
  h2 {
    font-weight: 500;
  }
  .clinical-case-card-container {
    margin-bottom: 15px;
  }
  @media (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;
    h2 {
      margin: 30px 15px 25px 15px;
    }
  }
`;
const ClinicAd = styled.div`
  background-color: white;
  border: 10px solid rgb(41, 90, 236);
  box-shadow: 2px 4px 9px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  margin-bottom: 15px;
  font-size: 18px;
  .text {
    position: absolute;
    width: 100%;
    &.top {
      top: 50px;
      .title {
        font-size: 42px;
        font-weight: ${fontWeight.bold};
      }
    }
    &.bottom {
      bottom: 60px;
      .inner {
        max-width: 450px;
        margin: auto;
        padding: 0 15px;
      }
      .btn-container {
        max-width: fit-content;
        margin: auto;
        margin-top: 15px;
        margin-top: 10px;
        button {
          border-radius: 5px;
          padding: 20px 50px;
        }
      }
    }
  }
  img {
    max-width: 100%;
    padding: 40px 0 1px 0;
  }
  p {
    margin-top: 10px;
    line-height: 110%;
  }
  @media (max-width: 768px) {
    .text {
      &.top {
        top: 30px;
        .title {
          font-size: 36px;
        }
      }
      &.bottom {
        bottom: 30px;
        .btn-container {
          button {
            padding: 10px 25px;
          }
        }
      }
    }
  }
`;
const StyledButton = styled.button`
  font-family: ${fontFamily.sansSerif};
  border: none;
  padding: 8px 15px;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  background: ${blue.bright};
  color: white;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.5);
`;
