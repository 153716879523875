import React, { useEffect, Fragment } from "react";
import ReactGA from "react-ga";
import aboveFoldDesktop from "../Static/above-fold.svg";
import aboveFoldMobile from "../Static/above-fold-mobile.svg";
import caseDesktop from "../Static/case-desktop.svg";
import caseMobile from "../Static/case-mobile.svg";
import collectionsDesktop from "../Static/collections-desktop.svg";
import collectionsMobile from "../Static/collections-mobile.svg";

// Dependencies
import { Link, Route } from "react-router-dom";

// Components
import Spinner from "../Utilities/Spinner";
import CollectionCard from "../Collection/CollectionCard";
import Header from "../Header/Header";
import HeaderTransparent from "../Header/HeaderTransparent";
import Footer from "../Footer/Footer";

// Styles
import styled from "styled-components";
import { H1, LargeText, MediumText } from "../Typography";
import { fontFamily, blue } from "../Utilities";
// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLaptopCode } from "@fortawesome/pro-solid-svg-icons";

// Apollo, GraphQL
// import { Query } from "react-apollo";
// import gql from "graphql-tag";

// Apollo, GraphQL
import { gql, useQuery } from '@apollo/client';

const GET_COLLECTIONS = gql`
  query GET_COLLECTIONS {
    collections {
      id
      collectionUrl
      collectionTitle
      codehealthCollectionId
      description
      thumbnail
      checkoutThumbnail
      caseCount
      price
      salePrice
      isSale
      isLive
    }
  }
`;

export default function Home(props) {
  useEffect(() => {
    window.scrollTo(0, 0);

    ReactGA.pageview("/");
  }, []);

  const { loading, error, data } = useQuery(GET_COLLECTIONS);

  if (loading) return null;
  if (error) return <h1>Server Error</h1>;

  return (
    <Fragment>
      {props.user ? (
        <Header user={props.user} />
      ) : (
        <HeaderTransparent />
      )}
      {!props.user && (
        <Fragment>
          <FullContainer
            backgroundImgDesktop={aboveFoldDesktop}
            backgroundImgMobile={aboveFoldMobile}
            className="above-fold-container"
          >
            <div className="text-block">
              <h1>Learn Medicine with Real-Life Cases</h1>
              <p>
                Clinical Rounds is where you will find collections of
                interactive clinical case scenarios in multiple choice
                format.
              </p>
              <Link className="explore-btn" to="/collections">
                Explore Collections
              </Link>
            </div>
          </FullContainer>

          <FullContainer
            backgroundImgDesktop={caseDesktop}
            backgroundImgMobile={caseMobile}
            className="cases-container"
          >
            <div className="text-block">
              <h1>Clinical Cases</h1>
              <div className="subtitle">
                Interactive scenarios presented in multiple choice
                format
              </div>
              <div className="points">
                <span className="marker">i</span>

                <div>
                  <div className="points-title">
                    REAL-LIFE SCENARIOS
                  </div>
                  <p>
                    Cases allow you to actively think by presenting
                    content as a real-life scenario - forget about
                    passive textbook reading!
                  </p>
                </div>
              </div>
              <div className="points">
                <span className="marker">ii</span>
                <div>
                  <div className="points-title">ANALYZE</div>
                  <p>
                    Case feedback allows you to see how your answers
                    compare to other students and healthcare
                    professionals
                  </p>
                </div>
              </div>
              <div className="points">
                <span className="marker">iii</span>
                <div>
                  <div className="points-title">LEARN</div>
                  <p>
                    Understand why a choice was the best answer and
                    why the others were wrong, while learning more about
                    the underlying pathology.
                  </p>
                </div>
              </div>
            </div>
          </FullContainer>

          <FullContainer
            backgroundImgDesktop={collectionsDesktop}
            backgroundImgMobile={collectionsMobile}
            className="collection-container"
          >
            <div className="text-block">
              <h1>Collections</h1>
              <div className="subtitle">
                Cases are grouped into collections based on related
                themes or categories
              </div>
              <p>
                <strong>Periodically Updated.</strong> Cases within each
                collection are added/removed based on peer feedback,
                while individual cases frequently reviewed and revised
                to reflect the newest recommendations and scientific
                discoveries.
              </p>
              <p>
                <strong>Track your progress.</strong> Each collection
                keeps track of the cases you've answered correct vs
                incorrect so you can track your progress.
              </p>
            </div>
          </FullContainer>
        </Fragment>
      )}

      <HomeContainer>
        <div className="body">
          <div className="section-label">
            <h2>Explore Collections</h2>
          </div>
          <div className="body-grid">
            {data.collections.map(collection => {
              if (collection.isLive) {
                return (
                  <CollectionCard
                    key={collection.id}
                    collection={collection}
                    {...props}
                  />
                );
              }
            })}
          </div>
        </div>

        {!props.user && (
          <div className="body more">
            <h2>More Collections Added Periodically</h2>
            <LargeText>
              Be sure to check back frequently or sign-up for a free
              Medzcool account and we'll email you when new collections
              are available.
            </LargeText>
            <div
              className="end-btn"
              onClick={() =>
                (window.location =
                  "https://medzcool.com/register/rounds")
              }
            >
              Create An Account
            </div>
          </div>
        )}
      </HomeContainer>
      <Footer />
    </Fragment>
  );
}
const FullContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  background-image: url(${props => props.backgroundImgDesktop});
  position: relative;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 56vw;
  font-family: ${fontFamily.sansSerif};
  .text-block {
    justify-self: center;
    align-self: center;
    position: relative;
    max-width: 60%;
    h1 {
      margin-bottom: 5px;
      font-size: 55px;
    }
    .subtitle {
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 50px;
    }
    p {
      font-size: 20px;
    }
    .explore-btn {
      border: 2px solid black;
      border-radius: 10px;
      padding: 8px 25px;
      width: fit-content;
      cursor: pointer;
      margin-top: 40px;
      display: block;
      text-decoration: none;
      color: black;
    }
    .points {
      display: grid;
      grid-template-columns: 1fr 10fr;
      grid-gap: 20px;
      margin-bottom: 25px;
      p {
        margin-top: 0;
      }
      .points-title {
        font-weight: 700;
        font-size: 22px;
      }
    }
    .marker {
      background-color: #7f65c1;
      color: white;
      padding: 5px 15px;
      border-radius: 15px;
      align-self: center;
      justify-self: center;
      font-weight: 700;
      font-size: 18px;
    }
  }
  @media (max-width: 1200px) {
    margin-bottom: 150px;
    .text-block {
      max-width: 75%;
    }
  }
  @media (max-width: 1024px) {
    .text-block {
      max-width: 75%;
      h1 {
        font-size: 30px;
      }
      p {
        font-size: 18px;
      }
      .subtitle {
        font-size: 18px;
        font-weight: 400;
      }
    }
  }
  @media (max-width: 900px) {
    &.cases-container {
      height: fit-content;
    }
  }
  @media (max-width: 768px) {
    background-image: url(${props => props.backgroundImgMobile});
    grid-template-columns: 1fr;
    height: 100vw;
    padding: 0 15px;
    margin-bottom: 100px;
    .text-block {
      justify-self: left;
      max-width: 65%;
      h1 {
        font-size: 26px;
      }
      p {
        font-size: 18px;
      }
      .subtitle {
        font-size: 18px;
        font-weight: 400;
      }
      .explore-btn {
        margin-top: 20px;
      }
      .points {
        grid-template-columns: 1fr;
        margin-bottom: 10px;
        .points-title {
          font-size: 17px;
        }
        .marker {
          display: none;
        }
      }
    }
  }
  @media (max-width: 425px) {
    margin-bottom: 70px;
    &.above-fold-container {
      height: 110vw;
    }
    &.collection-container {
      height: fit-content;
    }
  }
  @media (max-width: 375px) {
    &.above-fold-container {
      margin-top: 50px;
    }
  }
`;
const HomeContainer = styled.div`
  padding: 15px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 75px;
  max-width: 1300px;
  .heading {
    padding: 25px 0 50px 0;
    .title {
      font-weight: 700;
      font-family: ${fontFamily.sansSerif};
      font-size: 36px;
      margin-bottom: 20px;
    }
  }
  .desc {
    display: grid;
    grid-template-columns: 1.8fr 1fr;
    .heading-text {
      align-self: center;
    }
    img {
      width: 100%;
    }
    .subtitle {
      font-size: 18px;
      p {
        margin: 5px 0 0;
      }
      .beta {
        margin-top: 50px;
        display: grid;
        grid-template-columns: min-content auto;
        grid-gap: 15px;
        align-items: center;
        color: ${blue.bright};
        font-size: 13px;
      }
    }
  }
  .body {
    padding: 50px 0 50px 0;
    h2 {
      margin-bottom: 50px;
    }
    &.more {
      font-family: ${fontFamily.sansSerif};
      margin-top: 100px;
      margin-bottom: 75px;
      h2 {
        margin-bottom: 0;
      }
      .end-btn {
        background: black;
        padding: 10px 25px;
        border-radius: 5px;
        font-weight: 700;
        color: white;
        cursor: pointer;
        width: fit-content;
        margin-top: 25px;
      }
    }
  }
  .body-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 120px 50px;
  }
  h2 {
    font-size: 28px;
    font-family: ${fontFamily.sansSerif};
  }
  @media (max-width: 1024px) {
    .body-grid {
      grid-gap: 75px 25px;
    }
  }
  @media (max-width: 768px) {
    .body {
      padding: 25px 0 50px 0;
    }
    .body-grid {
      grid-template-columns: 1fr 1fr;
    }
    h2 {
      font-size: 22px;
    }
    .heading {
      .title {
        font-size: 28px;
      }
    }
    .desc {
      grid-template-columns: 1fr;
      grid-gap: 0px;
      .subtitle {
        font-size: 16px;
      }
      img {
        display: none;
      }
    }
  }
  @media (max-width: 425px) {
    .heading {
      padding: 15px 0 25px 0;
    }
    .desc {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 15px;
    }
    .body {
      h2 {
        margin: 0px 0 35px 0;
      }
    }
    .body-grid {
      grid-template-columns: 1fr;
      grid-gap: 75px;
    }
  }
`;
