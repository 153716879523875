import React from "react";
import ReactGA from "react-ga";

// Styles
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAmbulance, faDiagnoses } from "@fortawesome/pro-solid-svg-icons";
import {
  Title,
  MediumText,
  LargeText,
  AnchorTag,
  fontWeight,
  fontFamily,
} from "../Typography";

export default function ClinicalCaseCard(props) {
  const { title, desc, caseId, mixpanel, currentEncounter } = props;

  function handleGAEvent(category, action, label) {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  }

  function handleClick() {
    handleGAEvent("Related Case", "Clicked", caseId);
    
    if (mixpanel) {
      mixpanel.track("Related Encounter", {
        App: "Rounds",
        "Encounter ID": currentEncounter.id,
        "Encounter Hash ID": currentEncounter.hashId,
        "Related Encounter Hash Id": caseId
      });
    }
    
    window.location = "https://rounds.medzcool.com/case/" + caseId;
  }

  return (
    <ClinicalCaseContainer onClick={() => handleClick()}>
      <div className="icon">
        <FontAwesomeIcon icon={faDiagnoses} />
      </div>
      <div>
        {/* <div className="label">FEATURED CLINICAL CASE</div> */}
        <h3>{title}</h3>
        <p>{desc}</p>
        <div className="answer-prompt">Answer Case</div>
      </div>
    </ClinicalCaseContainer>
  );
}

const ClinicalCaseContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 25px;
  align-items: center;
  background: #f8f8f8;
  padding: 25px;
  border-radius: 5px;
  font-family: ${fontFamily.sansSerif};
  max-width: 1200px;
  margin: auto;
  cursor: pointer;
  box-shadow: 2px 4px 9px rgba(0, 0, 0, 0.1);
  h3 {
    margin-bottom: 5px;
    margin-top: 10px;
    font-size: 22px;
  }
  p {
    margin: 0;
    line-height: 120%;
  }
  .label {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 5px;
  }
  .answer-prompt {
    color: #295aec;
    margin: 8px 0 0 0;
    font-size: 14px;
  }
  .icon {
    font-size: 32px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 0px;
    h3 {
      font-size: 1.25em;
    }
    .icon {
      font-size: 24px;
      color: gray;
    }
  }
`;
